<template>
  <el-dialog :title="!dataForm.cabinId ? '新增油水舱室进度' : '修改油水舱室进度'" :close-on-click-modal="false" :visible.sync="visible"
    width="650px">
    <el-form ref="dataForm" :model="dataForm" inline label-width="140px" :rules="dataRule" label-position="left">
      <el-row>
        <el-form-item label="标的物" prop="subjectNo">
          <el-select class="selItemInput" v-model="dataForm.subjectNo" placeholder="请选择" @change="handleChange"
            :disabled="updateSortFlg">
            <el-option v-for="item in $store.state.common.navBarSubjectList" :key="item.displayValue"
              :label="item.displayName" :value="item.displayValue">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="序号" prop="sort">
          <el-input-number v-model="dataForm.sort" controls-position="right" :min="1"
            disabled="true"></el-input-number>
        </el-form-item>
        <el-form-item label="舱室名称" prop="cabinName">
          <el-input class="selItemInput" type="input" v-model="dataForm.cabinName" placeholder="请填写">
          </el-input>
        </el-form-item>
        <el-form-item label="管系完整性">
          <el-date-picker class="selItemInput" value-format="yyyy-MM-dd" v-model="dataForm.pipingIntegrityTime"
            type="date" style="width:300px" :disabled="unusePipingIntegrityTimeFlg">
          </el-date-picker>
          <el-label style="padding:0 10px;">使用</el-label>
          <el-switch v-model="unusePipingIntegrityTimeFlg" active-color="#cdcdce" inactive-color="#7dbae2"></el-switch>
        </el-form-item>
        <el-form-item label="电舾装完整性">
          <el-date-picker class="selItemInput" value-format="yyyy-MM-dd" v-model="dataForm.electricalIntegrityTime"
            type="date" style="width:300px" :disabled="unuseElectricalIntegrityTimeFlg">
          </el-date-picker>
          <el-label style="padding:0 10px;">使用</el-label>
          <el-switch v-model="unuseElectricalIntegrityTimeFlg" active-color="#cdcdce" inactive-color="#7dbae2"></el-switch>
        </el-form-item>
        <el-form-item label="密性试验">
          <el-date-picker class="selItemInput" value-format="yyyy-MM-dd" v-model="dataForm.densityIntegrityTime"
            type="date" style="width:300px" :disabled="unuseDensityIntegrityTimeFlg">
          </el-date-picker>
          <el-label style="padding:0 10px;">使用</el-label>
          <el-switch v-model="unuseDensityIntegrityTimeFlg" active-color="#cdcdce" inactive-color="#7dbae2"></el-switch>
        </el-form-item>
        <el-form-item label="强度试验">
          <el-date-picker class="selItemInput" value-format="yyyy-MM-dd" v-model="dataForm.strengthIntegrityTime"
            type="date" style="width:300px" :disabled="unuseStrengthIntegrityTimeFlg">
          </el-date-picker>
          <el-label style="padding:0 10px;">使用</el-label>
          <el-switch v-model="unuseStrengthIntegrityTimeFlg" active-color="#cdcdce" inactive-color="#7dbae2"></el-switch>
        </el-form-item>
        <el-form-item label="涂装完工">
          <el-date-picker class="selItemInput" value-format="yyyy-MM-dd" v-model="dataForm.paintingIntegrityTime"
            type="date" style="width:300px" :disabled="unusePaintingIntegrityTimeFlg">
          </el-date-picker>
          <el-label style="padding:0 10px;">使用</el-label>
          <el-switch v-model="unusePaintingIntegrityTimeFlg" active-color="#cdcdce" inactive-color="#7dbae2"></el-switch>
        </el-form-item>
        <el-form-item label="完工封舱">
          <el-date-picker class="selItemInput" value-format="yyyy-MM-dd" v-model="dataForm.closeCabinIntegrityTime"
            type="date" style="width:300px" :disabled="unuseCloseCabinIntegrityTimeFlg">
          </el-date-picker>
          <el-label style="padding:0 10px;">使用</el-label>
          <el-switch v-model="unuseCloseCabinIntegrityTimeFlg" active-color="#cdcdce" inactive-color="#7dbae2"></el-switch>
        </el-form-item>
        <el-form-item label="备注">
          <el-input class="width391" type="input" v-model="dataForm.remark" placeholder="请填写">
          </el-input>
        </el-form-item>
      </el-row>
      <el-row class="formBtnGroup">
        <el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="visible = false">取消</el-button>
        <el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="dataFormSubmit()">确定</el-button>
      </el-row>
    </el-form>
  </el-dialog>
</template>

<script>
import $common from "@/utils/common.js"
export default {
  name: "cabinprogressinfo-add-or-update",
  data() {
    return {
      visible: true,
      unusePipingIntegrityTimeFlg: false,
      unuseElectricalIntegrityTimeFlg: false,
      unuseDensityIntegrityTimeFlg: false,
      unuseStrengthIntegrityTimeFlg: false,
      unusePaintingIntegrityTimeFlg: false,
      unuseCloseCabinIntegrityTimeFlg: false,
      updateSortFlg: false,
      form: {},
      dataForm: {
        workNo: $common.getItem("workNo"),
        subjectNo: '',
        cabinId: '',
        cabinName: '',
        pipingIntegrityTime: "",
        unusePipingIntegrityTime: '',
        electricalIntegrityTime: '',
        unuseElectricalIntegrityTime: '',
        densityIntegrityTime: '',
        unuseDensityIntegrityTime: '',
        strengthIntegrityTime: '',
        unuseStrengthIntegrityTime: '',
        paintingIntegrityTime: '',
        unusePaintingIntegrityTime: '',
        closeCabinIntegrityTime: '',
        unuseCloseCabinIntegrityTime: '',
        remark: '',
        sort: 1
      },
      dataRule: {
        subjectNo: [
          { required: true, message: "标的物不能为空", trigger: "blur" }
        ],
        sort: [
          { required: true, message: "序号不能为空", trigger: "blur" }
        ],
        cabinName: [
          { required: true, message: "舱室名称不能为空", trigger: "blur" }
        ]
      },
      contractSigns: [{
        value: '0',
        label: '未签订'
      }, {
        value: '1',
        label: '已签订'
      }],
    };
  },
  components: {

  },
  // mounted() {
  // 	this.getSubjectDropDown();
  // },
  methods: {
    getCurrentTime() {
      //获取当前时间并打印
      var _this = this;
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      let hh = new Date().getHours();
      let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
      let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
      return yy + '-' + mm + '-' + dd;
    },
    init(id) {
      this.dataForm = {
        workNo: $common.getItem("workNo"),
        subjectNo: '',
        cabinId: '',
        cabinName: '',
        pipingIntegrityTime: "",
        electricalIntegrityTime: '',
        densityIntegrityTime: '',
        strengthIntegrityTime: '',
        paintingIntegrityTime: '',
        closeCabinIntegrityTime: '',
        remark: '',
        sort: 1
      }
      this.dataForm.cabinId = id ? id : 0;
      if (id) {
        this.getDetail();
      }
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        this.visible = true;
      })
      if (id) {
        this.updateSortFlg = true
      } else {
        this.updateSortFlg = false
      }
    },
    getDetail() {
      this.$http({
        url: this.$store.state.httpUrl + "/business/cabinprogressinfo/info/" + this.dataForm.cabinId,
        method: "get",
        params: {},
      }).then(({
        data
      }) => {
        if (data && data.resultCode === 200) {
          this.dataForm = data.body;
          if (this.dataForm.unusePipingIntegrityTime == '1') {
            this.unusePipingIntegrityTimeFlg = true
          } else {
            this.unusePipingIntegrityTimeFlg = false
          }
          if (this.dataForm.unuseElectricalIntegrityTime == '1') {
            this.unuseElectricalIntegrityTimeFlg = true
          } else {
            this.unuseElectricalIntegrityTimeFlg = false
          }
          if (this.dataForm.unuseDensityIntegrityTime == '1') {
            this.unuseDensityIntegrityTimeFlg = true
          } else {
            this.unuseDensityIntegrityTimeFlg = false
          }
          if (this.dataForm.unuseStrengthIntegrityTime == '1') {
            this.unuseStrengthIntegrityTimeFlg = true
          } else {
            this.unuseStrengthIntegrityTimeFlg = false
          }
          if (this.dataForm.unusePaintingIntegrityTime == '1') {
            this.unusePaintingIntegrityTimeFlg = true
          } else {
            this.unusePaintingIntegrityTimeFlg = false
          }
          if (this.dataForm.unuseCloseCabinIntegrityTime == '1') {
            this.unuseCloseCabinIntegrityTimeFlg = true
          } else {
            this.unuseCloseCabinIntegrityTimeFlg = false
          }
        }
      });
    },
    handleChange() {
      if (this.dataForm.subjectNo) {
        this.getMaxSort();
      }
    },
    getMaxSort() {
      let _url = "/business/cabinprogressinfo/getMaxSort";
      this.$http({
        url: this.$store.state.httpUrl + _url,
        method: "post",
        data: this.$http.adornData({
          workNo: this.dataForm.workNo,
          subjectNo: this.dataForm.subjectNo,
        })
      }).then(({ data }) => {
        if (data && data.resultCode === 200) {
          this.$set(this.dataForm, 'sort', data.body)
        }
      });
    },
    dataFormSubmit() {
      this.$refs["dataForm"].validate(valid => {
        if (valid) {
          let _url = "/business/cabinprogressinfo/save";
          if (this.dataForm.cabinId && this.dataForm.cabinId !== 0) {
            _url = "/business/cabinprogressinfo/update";
          }
          if (this.unusePipingIntegrityTimeFlg) {
            this.dataForm.unusePipingIntegrityTime = '1'
          } else {
            this.dataForm.unusePipingIntegrityTime = '0'
          }
          if (this.unuseElectricalIntegrityTimeFlg) {
            this.dataForm.unuseElectricalIntegrityTime = '1'
          } else {
            this.dataForm.unuseElectricalIntegrityTime = '0'
          }
          if (this.unuseDensityIntegrityTimeFlg) {
            this.dataForm.unuseDensityIntegrityTime = '1'
          } else {
            this.dataForm.unuseDensityIntegrityTime = '0'
          }
          if (this.unuseStrengthIntegrityTimeFlg) {
            this.dataForm.unuseStrengthIntegrityTime = '1'
          } else {
            this.dataForm.unuseStrengthIntegrityTime = '0'
          }
          if (this.unusePaintingIntegrityTimeFlg) {
            this.dataForm.unusePaintingIntegrityTime = '1'
          } else {
            this.dataForm.unusePaintingIntegrityTime = '0'
          }
          if (this.unuseCloseCabinIntegrityTimeFlg) {
            this.dataForm.unuseCloseCabinIntegrityTime = '1'
          } else {
            this.dataForm.unuseCloseCabinIntegrityTime = '0'
          }
          this.$http({
            url: this.$store.state.httpUrl + _url,
            method: "post",
            data: this.$http.adornData(this.dataForm)
          }).then(({
            data
          }) => {
            if (data && data.resultCode === 200) {
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.visible = false;
                  this.$parent.getDataList();
                }
              });
            }
          });
        }
      });
    },

  }
};
</script>

<style lang="scss" scoped="scoped">
.selItemInput.descAreaLg {
  width: 718px !important;
}

.width391 {
  width: 391px !important;
}

.addImgBox {
  width: 136px;
  height: 136px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}


.fondstyle {
  color: #3f649f;
  font-size: 15px;
}
</style>
